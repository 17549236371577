<template>
  <b-navbar-nav v-if="this.$store.getters.getFrimaPreview" class="nav">
    <b-nav-item class="menuler">
      <b-dropdown
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          text="Finans"
          variant="secondary"
      >
        <b-dropdown-item to="/cariKartlar">
          <feather-icon icon="UsersIcon" size="14" class="text-dark mr-1"/>
          Cari Kartlar
        </b-dropdown-item>
        <b-dropdown-divider/>
        <b-dropdown-item to="/vardiyaOzet/0">
          <feather-icon icon="EyeIcon" size="14" class="text-dark mr-1"/>
          Vardiya Özeti
        </b-dropdown-item>
        <b-dropdown-item to="/vardiyaList">
          <feather-icon icon="ListIcon" size="14" class="text-dark mr-1"/>
          Vardiya Kayıtları
        </b-dropdown-item>
      </b-dropdown>
    </b-nav-item>
    <b-nav-item class="menuler">
      <b-dropdown
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          text="Kurum Bilgileri"
          variant="secondary"
      >
        <b-dropdown-item to="/odaListesi">
          <feather-icon icon="GridIcon" size="14" class="text-dark mr-1"/>
          Oda Listesi
        </b-dropdown-item>
        <b-dropdown-item to="/kullaniciListesi">
          <feather-icon icon="UsersIcon" size="14" class="text-dark mr-1"/>
          Kullanıcı Listesi
        </b-dropdown-item>
      </b-dropdown>
    </b-nav-item>
    <b-nav-item class="menuler">
      <b-dropdown
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          text="Konaklama Raporları"
          variant="secondary"
      >
        <b-dropdown-item to="/konaklamaListesi">
          <feather-icon icon="UsersIcon" size="14" class="text-dark mr-1"/>
          Konaklama Listesi
        </b-dropdown-item>
        <b-dropdown-item to="/rezervasyonListesi">
          <feather-icon icon="UsersIcon" size="14" class="text-dark mr-1"/>
          Rezervasyon Listesi
        </b-dropdown-item>
        <b-dropdown-item to="/onlineRezervasyonListesi">
          <feather-icon icon="UserCheckIcon" size="14" class="text-dark mr-1"/>
          Online Konaklamalar
        </b-dropdown-item>
        <b-dropdown-divider/>
        <b-dropdown-item to="/aylikRapor">
          <feather-icon icon="ArchiveIcon" size="14" class="text-dark mr-1"/>
          Aylık Tarife Ve Konaklama Raporu
        </b-dropdown-item>
        <b-dropdown-item to="/yillikRapor">
          <feather-icon icon="DatabaseIcon" size="14" class="text-dark mr-1"/>
          Yıllık Tarife Ve Konaklama Raporu
        </b-dropdown-item>
        <b-dropdown-item to="/odaRapor">
          <feather-icon icon="ActivityIcon" size="14" class="text-dark mr-1"/>
          Oda Aylık Konaklama Raporu
        </b-dropdown-item>
      </b-dropdown>
    </b-nav-item>
  </b-navbar-nav>
</template>

<script>
import {
  BDropdownDivider,
  BNavItemDropdown,
  BDropdown,
  BDropdownItem,
  BImg,
  BNavItem,
  BNavbarNav,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import HorizontalNavMenuHeaderLink
  from '../../../layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue'
import HorizontalNavMenuHeaderGroup
  from '../../../layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-group/HorizontalNavMenuGroup.vue'

export default {
  directives: {
    Ripple,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    BDropdownDivider,
    BDropdown,
    BNavItem,
    BNavbarNav,
    HorizontalNavMenuHeaderGroup,
    HorizontalNavMenuHeaderLink,
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale)
    },
  },
  setup() {
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      {
        locale: 'fr',
        img: require('@/assets/images/flags/fr.png'),
        name: 'French',
      },
      {
        locale: 'de',
        img: require('@/assets/images/flags/de.png'),
        name: 'German',
      },
      {
        locale: 'pt',
        img: require('@/assets/images/flags/pt.png'),
        name: 'Portuguese',
      },
    ]
    const resolveNavComponent = (item) => {
      if (item.children) return 'horizontal-nav-menu-header-group'
      return 'horizontal-nav-menu-header-link'
    }
    return {
      locales,
      resolveNavComponent,
    }
  },
}
</script>
<style>
.dropdown-item {
  padding: 0.35rem 1rem;
}

.dropdown-divider {
  margin: 0.1rem !important;
}
</style>

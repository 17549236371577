<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon class="text-white" icon="MenuIcon" size="21"/>
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <div
        class="bookmark-wrapper align-items-center d-flex"
    >
      <div class="d-flex align-items-center">
        <bookmarks v-if="width > 500"/>
        <kurumlar class="ml-50"/>
        <kurum-link :items="navMenuItems"/>
      </div>
      <!--      <b-row>-->
      <!--        <b-col class="d-flex align-items-center">-->
      <!--          <bookmarks v-if="width > 500"/>-->
      <!--          <kurumlar class="ml-50"/>-->
      <!--        </b-col>-->
      <!--        <b-col>-->
      <!--          <kurum-link :items="navMenuItems"/>-->
      <!--        </b-col>-->
      <!--      </b-row>-->
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block"/>
      <user-dropdown/>
    </b-navbar-nav>
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import { BLink, BNavbarNav } from 'bootstrap-vue'
import kurumlar from './components/kurumlar.vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import kurumLink from './components/kurumLink.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import { useWindowSize } from '@vueuse/core'

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
      navMenuItems,
    }
  },
  components: {
    BLink,
    // Navbar Components
    BNavbarNav,
    kurumlar,
    kurumLink,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
}
</script>

<template>
  <b-navbar-nav class="nav">
    <model-list-select
        class="kurum-select"
        :list="kurumList"
        v-model="kurum"
        option-value="firmaKodu"
        option-text="unvan"
        id="mySelectId"
        name="unvan"
        placeholder="Hızlı Seçim"
    />
  </b-navbar-nav>
</template>

<script>
import {
  BFormGroup,
  BNavbarNav,
  BNavItem,
  BTooltip,
  BNavItemDropdown,
  BFormInput,
  BDropdownItem,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select'

export default {
  components: {
    BFormGroup,
    ModelListSelect,
    BFormSelect,
    BFormSelectOption,
    vSelect,
    BNavbarNav,
    BNavItem,
    BTooltip,
    BNavItemDropdown,
    BFormInput,
    VuePerfectScrollbar,
    BDropdownItem,
  },
  data() {
    return {
      kurum: null,
    }
  },
  watch: {
    '$route'(to) {
      if (to.name === 'dash' || to.name === 'kurumKayit' || to.name === 'dokumantasyon') {
        this.kurum = null
        store.state.firmaPreview = false
      }
    },
    kurum(e) {
      if (e !== null) {
        store.state.firmaPreview = true
        localStorage.setItem('raporKurumKodu', e)
        this.$store.dispatch('tarife')
        store
            .dispatch('kurumDetay', e)
            .then((res, position = 'bottom-right') => {
              switch (this.$route.name) {
                case 'dash':
                  router.push('kurumDetay')
                  break
                case 'vardiyaOzet':
                  if (this.$route.params.id === '0') {
                    store
                        .dispatch('vardiyaOzeti', 0)
                        .then((res, position = 'bottom-right') => {
                          const mesaj = this.$store.getters.notificationSettings(res)
                          if (mesaj) {
                            this.$toast(
                                {
                                  component: ToastificationContent,
                                  props: {
                                    title: 'Başarılı',
                                    text: mesaj,
                                    icon: 'ThumbsUpIcon',
                                    variant: 'success',
                                  },
                                },
                                { position },
                            )
                          }
                        })
                  } else {
                    this.$router.push({ params: { id: 0 } })
                    store
                        .dispatch('vardiyaOzeti', 0)
                        .then((res, position = 'bottom-right') => {
                          const mesaj = this.$store.getters.notificationSettings(res)
                          if (mesaj) {
                            this.$toast(
                                {
                                  component: ToastificationContent,
                                  props: {
                                    title: 'Başarılı',
                                    text: mesaj,
                                    icon: 'ThumbsUpIcon',
                                    variant: 'success',
                                  },
                                },
                                { position },
                            )
                          }
                        })
                  }
                  break
                case 'vardiyaList':
                  this.$store
                      .dispatch('vardiyaListesi', { sayfala: false })
                      .then((res, position = 'bottom-right') => {
                        const mesaj = this.$store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast(
                              {
                                component: ToastificationContent,
                                props: {
                                  title: 'Başarılı',
                                  text: mesaj,
                                  icon: 'ThumbsUpIcon',
                                  variant: 'success',
                                },
                              },
                              { position },
                          )
                        }
                      })
                      .catch((err) => {
                        const mesaj = this.$store.getters.notificationSettings(err)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                  break
                case 'cariKartlar':
                  this.$store
                      .dispatch('cariKart')
                      .then((res, position = 'bottom-right') => {
                        this.totalRows = Object.values(res.cariListe).length
                        const mesaj = this.$store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast(
                              {
                                component: ToastificationContent,
                                props: {
                                  title: 'Başarılı',
                                  text: mesaj,
                                  icon: 'ThumbsUpIcon',
                                  variant: 'success',
                                },
                              },
                              { position },
                          )
                        }
                      })
                      .catch(err => {
                        const mesaj = this.$store.getters.notificationSettings(err)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                  break
                case 'giderKayitlari':
                  this.$router.push({ params: { id: 0 } })
                  this.$store
                      .dispatch('giderKayitlari', 0)
                      .then((res, position = position = 'bottom-right') => {
                        const mesaj = this.$store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast(
                              {
                                component: ToastificationContent,
                                props: {
                                  title: 'Başarılı',
                                  text: mesaj,
                                  icon: 'ThumbsUpIcon',
                                  variant: 'success',
                                },
                              },
                              { position },
                          )
                        }
                      })
                      .catch(err => {
                        const mesaj = this.$store.getters.notificationSettings(err)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                  break
                case 'konaklamaKayitlari':
                  this.$store
                      .dispatch('konaklamaKayitlari', 0)
                      .then((res, position = 'bottom-right') => {
                        const mesaj = this.$store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast(
                              {
                                component: ToastificationContent,
                                props: {
                                  title: 'Başarılı',
                                  text: mesaj,
                                  icon: 'ThumbsUpIcon',
                                  variant: 'success',
                                },
                              },
                              { position },
                          )
                        }
                      })
                      .catch(err => {
                        const mesaj = this.$store.getters.notificationSettings(err)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                  break
                case 'gelirKayitlari':
                  this.$router.push({ params: { id: 0 } })
                  this.$store
                      .dispatch('gelirKayitlari', 0)
                      .then((res, position = 'bottom-right') => {
                        const mesaj = this.$store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast(
                              {
                                component: ToastificationContent,
                                props: {
                                  title: 'Başarılı',
                                  text: mesaj,
                                  icon: 'ThumbsUpIcon',
                                  variant: 'success',
                                },
                              },
                              { position },
                          )
                        }
                      })
                      .catch(err => {
                        const mesaj = this.$store.getters.notificationSettings(err)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                  break
                case 'odaListesi':
                  store
                      .dispatch('odaList')
                      .then((res, position = 'bottom-right') => {
                        const mesaj = store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast(
                              {
                                component: ToastificationContent,
                                props: {
                                  title: 'Başarılı',
                                  text: mesaj,
                                  icon: 'ThumbsUpIcon',
                                  variant: 'success',
                                },
                              },
                              { position },
                          )
                        }
                      })
                      .catch(err => {
                        const mesaj = store.getters.notificationSettings(err)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                  break
                case 'userList':
                  this.$store.dispatch('userList')
                      .then(res => {
                        const mesaj = this.$store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Başarılı',
                              text: mesaj,
                              icon: 'ThumbsUpIcon',
                              variant: 'success',
                            },
                          })
                        }
                      })
                      .catch(err => {
                        const mesaj = this.$store.getters.notificationSettings(err)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                  break
                case 'kullaniciListesi':
                  this.$store.dispatch('userList')
                      .then(res => {
                        const mesaj = this.$store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Başarılı',
                              text: mesaj,
                              icon: 'ThumbsUpIcon',
                              variant: 'success',
                            },
                          })
                        }
                      })
                      .catch(err => {
                        const mesaj = this.$store.getters.notificationSettings(err)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                  break
                case 'konaklamaListesi':
                  this.$store
                      .dispatch('konaklamaListActive', {
                        uzunluk: this.perPage,
                        baslangic: 0,
                      })
                      .then((res, position = 'bottom-right') => {
                        this.islem = false
                        this.todayToplamKayit = res.aktifSayac
                        this.totalRows = res.aktifKonuklar.length
                        const mesaj = this.$store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast(
                              {
                                component: ToastificationContent,
                                props: {
                                  title: 'Başarılı',
                                  text: mesaj,
                                  icon: 'ThumbsUpIcon',
                                  variant: 'success',
                                },
                              },
                              { position },
                          )
                        }
                      })
                      .catch(err => {
                        const mesaj = this.$store.getters.notificationSettings(err)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                  this.$store
                      .dispatch('konaklamaListPasif', {
                        uzunluk: this.perPage,
                        baslangic: 0,
                      })
                      .then((res, position = 'bottom-right') => {
                        this.islem = false
                        this.TtodayToplamKayit = res.pasifSayac
                        this.TtotalRows = res.gelecekKonuklar.length
                        const mesaj = this.$store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast(
                              {
                                component: ToastificationContent,
                                props: {
                                  title: 'Başarılı',
                                  text: mesaj,
                                  icon: 'ThumbsUpIcon',
                                  variant: 'success',
                                },
                              },
                              { position },
                          )
                        }
                      })
                      .catch(err => {
                        const mesaj = this.$store.getters.notificationSettings(err)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                  break
                case 'rezervasyonListesi':
                  this.$store.dispatch('rezervasyonList', {
                    baslangic: 0,
                    uzunluk: this.perPage,
                    searchKey: this.filter,
                    start: this.baslangic,
                    end: this.bitis,
                  })
                      .then((res, position = 'bottom-right') => {
                        this.totalRows = res.sayac
                        this.toplamKayit = res.list.length
                        const mesaj = this.$store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Başarılı',
                              text: mesaj,
                              icon: 'ThumbsUpIcon',
                              variant: 'success',
                            },
                          }, { position })
                        }
                      })
                      .catch(err => {
                        const mesaj = this.$store.getters.notificationSettings(err)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                  break
                case 'onlineRezervasyonListesi':
                  this.$store
                      .dispatch('onlineRezListAktive', {
                        uzunluk: this.perPage,
                        baslangic: 0,
                      })
                      .then((res, position = 'bottom-right') => {
                        this.islem = false
                        this.todayToplamKayit = res.aktifKonuklarSayac
                        this.totalRows = res.aktifKonuklar.length
                        const mesaj = this.$store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast(
                              {
                                component: ToastificationContent,
                                props: {
                                  title: 'Başarılı',
                                  text: mesaj,
                                  icon: 'ThumbsUpIcon',
                                  variant: 'success',
                                },
                              },
                              { position },
                          )
                        }
                      })
                      .catch(err => {
                        const mesaj = this.$store.getters.notificationSettings(err)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                  this.$store
                      .dispatch('onlineRezListGelecek', {
                        uzunluk: this.perPage,
                        baslangic: 0,
                      })
                      .then((res, position = 'bottom-right') => {
                        this.islem = false
                        this.TtodayToplamKayit = res.gelecekKonuklarSayac
                        this.TtotalRows = res.gelecekKonuklar.length
                        const mesaj = this.$store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast(
                              {
                                component: ToastificationContent,
                                props: {
                                  title: 'Başarılı',
                                  text: mesaj,
                                  icon: 'ThumbsUpIcon',
                                  variant: 'success',
                                },
                              },
                              { position },
                          )
                        }
                      })
                      .catch(err => {
                        const mesaj = this.$store.getters.notificationSettings(err)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                  break
                case 'aylikRapor':
                  let today = new Date()
                  let month = today.getMonth() + 1
                  month = month < 10 ? '0' + month : month
                  this.year = today.getFullYear();
                  (this.month =
                      month == '01'
                          ? 'Ocak'
                          : '' || month == '02'
                              ? 'Şubat'
                              : '' || month == '03'
                                  ? 'Mart'
                                  : '' || month == '04'
                                      ? 'Nisan'
                                      : '' || month == '05'
                                          ? 'Mayıs'
                                          : '' || month == '06'
                                              ? 'Haziran'
                                              : '' || month == '07'
                                                  ? 'Temmuz'
                                                  : '' || month == '08'
                                                      ? 'Ağustos'
                                                      : '' || month == '09'
                                                          ? 'Eylül'
                                                          : '' || month == '10'
                                                              ? 'Ekim'
                                                              : '' || month == '11'
                                                                  ? 'Kasım'
                                                                  : '' || month == '12'
                                                                      ? 'Aralık'
                                                                      : ''),
                      (this.filtre = true)
                  this.$store
                      .dispatch('aylikRapor', {
                        year: this.year,
                        month: this.month,
                      })
                      .then((res, position = 'bottom-right') => {
                        const mesaj = this.$store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast(
                              {
                                component: ToastificationContent,
                                props: {
                                  title: 'Başarılı',
                                  text: mesaj,
                                  icon: 'ThumbsUpIcon',
                                  variant: 'success',
                                },
                              },
                              { position },
                          )
                        }
                      })
                      .catch(err => {
                        const mesaj = this.$store.getters.notificationSettings(err)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                  break
                case 'yillikRapor':
                  this.$store
                      .dispatch('yillikRapor', {
                        year: 2023,
                      })
                      .then((res, position = 'bottom-right') => {
                        const mesaj = this.$store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast(
                              {
                                component: ToastificationContent,
                                props: {
                                  title: 'Başarılı',
                                  text: mesaj,
                                  icon: 'ThumbsUpIcon',
                                  variant: 'success',
                                },
                              },
                              { position },
                          )
                        }
                      })
                      .catch(err => {
                        const mesaj = this.$store.getters.notificationSettings(err)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                  break
                case 'odaRapor':
                  const todayy = new Date()
                  const monthh = String(todayy.getMonth() + 1)
                      .padStart(2, '0')
                  var yyyy = todayy.getFullYear()
                  this.year = yyyy
                  this.filtre = true
                  this.$store
                      .dispatch('odaRapor', {
                        year: this.year,
                        month: '01',
                      })
                      .then((res, position = 'bottom-right') => {
                        const mesaj = this.$store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast(
                              {
                                component: ToastificationContent,
                                props: {
                                  title: 'Başarılı',
                                  text: mesaj,
                                  icon: 'ThumbsUpIcon',
                                  variant: 'success',
                                },
                              },
                              { position },
                          )
                        }
                      })
                      .catch(err => {
                        const mesaj = this.$store.getters.notificationSettings(err)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      })
                  break
                case 'raporGunDetay':
                  router.push('/kurumDetay')
                  break
                default:
              }
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
            })
            .catch(err => {
              const mesaj = this.$store.getters.notificationSettings(err)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            })
      } else {
        store.state.firmaPreview = false
      }
    },
  },
  computed: {
    kurumList() {
      return store.getters.getKurumList
    },
  },
  methods: {},
  beforeCreate() {
    this.$router.beforeResolve((to, from, next) => {
      if (to.name !== 'dash') {
        this.kurum = store.getters.getKurumList.find(x => x.firmaKodu === localStorage.getItem('raporKurumKodu'))?.firmaKodu
      }
      next()
    })
  },
  created() {
  },
  beforeDestroy() {
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  min-width: 200px !important;
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__dropdown-menu {
  background-color: coral !important;
  min-width: 800px !important;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  min-width: 200px !important;
  fill: #394066;
}
</style>
<style scoped>
.kurum-select {
  min-width: 300px !important;
  background-color: #3d2c60 !important;
  border-color: transparent !important;
}

.kurum-select, .text {
  color: white !important;
}
</style>
